import React from 'react'
import * as AiIcons from 'react-icons/ai'

function SocialIcons() {
  return (

      <div className='text-white flex justify-center pb-4'>
        <a href='https://www.instagram.com/bellablueevents'>
        <AiIcons.AiOutlineInstagram size={30} className='hover:text-black m-4'/>
        </a>
<a href='https://www.facebook.com/bellablueevents'>
        <AiIcons.AiOutlineFacebook size={30} className='hover:text-black m-4'/>
</a>
        <a href='https://wa.me/447827276923'>
        <AiIcons.AiOutlineWhatsApp size={30} className='hover:text-black m-4'/>
        </a>
 
      </div>
  )
}

export default SocialIcons