import React from 'react'
import {motion} from 'framer-motion'

function About() {
  return (
    <motion.div 
    initial={{translateX:'100%'}}
    animate={{translateX:'0%', transition: {duration: 2}}}
    exit={{translateX:'-100%', transition: {duration: 0.4}}}
    className='h-screen text-gray-700 pt-32'>
    <div className='bg-bellablue h-screen p-8'>
    <h2 className='text-center p-8'>About</h2>
    </div>
    </motion.div>
  )
}

export default About