import React from 'react'
import { Link } from 'react-router-dom'
import NavData from './NavData'
import logo from '../Assets/Images/logo.png'

function SideNav() {
  return (
    <div className='fixed right-0 top-0 h-screen bg-belladarker w-1/4 text-center p-8 pt-16 border border-l-1 border-r-0 border-t-0 border-b-0'>
        <img src ={logo} />
        {NavData.map(item => (
            <div className='p-4'>
                <Link to={item.path}>
                <p className='text-white hover:text-slate-500 hover:underline'>{item.title}</p>
                </Link>
                </div>
        ))}
    </div>
  )
}

export default SideNav