import React from 'react'
import EmailForm from '../Components/EmailForm'
import {motion} from 'framer-motion'


function Enquiry() {
    return (
      <motion.div 
      initial={{translateY:'100%'}}
      animate={{translateY:'0%', transition: {duration: 2}}}
      exit={{translateY:'-100%', transition: {duration: 0.4}}}
      className='text-gray-700 p-8 pt-4 bg-bellablue'>


        <h2 className='text-center p-8'>Enquiry</h2>


      <EmailForm />
    
      </motion.div>
      )
}

export default Enquiry