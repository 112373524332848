import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../Components/Tools/WindowDimensions'
import * as AiIcons from 'react-icons/ai'
import GalleryImages from '../Components/GalleryImages'
import { motion } from 'framer-motion'

function Portfolio() {
  const [selectedImage, setSelectedImage] = useState('')
  const [modal, setModal] = useState(false)
  const { width, height } = useWindowDimensions()
  const [fadeIn, setFadeIn] = useState(false)

  function importAll(r) {
    return r.keys().map(r)
  }

  const images = importAll(
    require.context('../Assets/Images/Gallery/', false, /\.(png|jpe?g|svg)$/)
  )
  useEffect(() => {
    setFadeIn(true)
  })

  return (
    <>
      {modal ? (
        <div className='fixed flex h-screen w-screen bg-pastel-darker/70 z-30'>
          <div className='m-auto'>
            <div className='bg-white'>
              <div className='flex justify-end items-center' onClick={() => setModal(false)}>
                <AiIcons.AiOutlineClose className='text-red-600' size={40} />
              </div>
              <img
                src={selectedImage}
                style={
                  width > height
                    ? {
                        height: '70vh',
                        width: 'auto',
                        margin: 10,
                        marginBottom: 20,
                        paddingBottom: 20,
                      }
                    : { height: 'auto', width: '100vw', padding: 20 }
                }
                alt='large display of selected'
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className='text-gray-700 pt-4 bg-bellablue'>
        <h2 className='text-center p-8'>Portfolio</h2>

        <div className={fadeIn ? 'grid tablet:grid-cols-3 opacity-1 duration-700 transition' : 'grid tablet:grid-cols-3 opacity-0 duration-700 transition'}>
          {images.map(img => (
            <div className='flex justify-center'>
              <div
                className='m-4 tablet:m-16 border border-8 border-pastel-darker overflow-hidden hover:scale-125 duration-300'
                style={{ minHeight: 300, minWidth: 300, maxHeight: 300, maxWidth: 300 }}
                key={img.img}>
                <img
                  src={img}
                  alt={img.alt}
                  className='object-cover h-full w-full'
                  onMouseEnter={() => setSelectedImage(img)}
                  onClick={() => setModal(true)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Portfolio
