import React, { useState } from 'react'
import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'
import logo from '../Assets/Images/logo.png'
import NavData from './NavData'

function HeaderBar(props) {
    const displayTitle = props.title
    const [showNav, setShowNav] = useState(false)
  return (
    <>
      <div className='flex bg-belladarker justify-between items-center border border-b-1 border-t-0 border-l-0 border-r-0 p-8 z-50'>
<div>
<img src={logo} className='w-32'/>
</div>
    <div className='z-50'>
<AiIcons.AiOutlineMenu style={{color:'#fff'}} size={30} onClick={() => setShowNav(!showNav)}/>
    </div>
      </div>

{showNav ?
      <div className='fixed top-0 h-screen w-screen bg-belladarker/80 flex z-40'>
        <div className='m-auto text-center'>
        {NavData.map(item => (
            <div className='p-4'>
                <Link to={item.path} onClick={() => setShowNav(false)}>
                <p className='text-white hover:text-slate-500 hover:underline'>{item.title}</p>
                </Link>
                </div>
        ))}
        </div>
      </div>
      : null}
    </>
  )
}

export default HeaderBar