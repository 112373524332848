import React from 'react'

const NavData = [
    {
    title: 'Home',
    path: '/'
},
// {
//     title: 'About Us',
//     path: '/about'
// },
{
    title: 'Portfolio',
    path: '/portfolio'
},
{
    title: 'Enquiry Form',
    path: '/enquiry'
},
{
    title: 'Charity Work',
    path: '/charity'
},
{
    title: 'FAQs',
    path: '/faqs'
},
]

export default NavData