import React from 'react'
import {motion} from 'framer-motion'
import maxTheBrave from '../Assets/Images/maxTheBrave.webp'
import stRaphs from '../Assets/Images/stRaphs.webp'
import useWindowDimensions from '../Components/Tools/WindowDimensions'

function Charity() {
  const {width} = useWindowDimensions()
    return (
      <motion.div 
      initial={{translateY:'100%'}}
      animate={{translateY:'0%', transition: {duration: 2}}}
      exit={{translateY:'-100%', transition: {duration: 0.4}}}
      className='h-full text-gray-700 pt-8 bg-bellablue'>


        <h2 className='text-center p-8'>Our charity work</h2>

<div className='grid tablet:grid-cols-1'>

<div className='p-16 text-center'>
<h3 className='text-6xl pb-4'>St Raphaels Hospice</h3>
<div className='flex justify-center'>
<img src={stRaphs} className='w-64'/>
</div>
<p className='pt-4'>We reached out to St Raphaels Hospice for their International Nurses 
Day and donated this balloon garland in order for them to really celebrate. 
After Covid, it has become very clear that nurses/doctors/ the NHS in general are the backbone 
of our community and we wanted them to have the recognition that they truly deserved!</p>
</div>



</div>
   
      </motion.div>
      )
}

export default Charity