import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from 'date-fns/locale/en-GB';

export default function EmailForm() {
  const [emailSent, setEmailSent] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [dateNotSet, setDateNotSet] = useState(false);
  registerLocale('en-GB', enGB)
  setDefaultLocale('en-GB');

  const handleClick =() => {
    setDateNotSet(!dateNotSet)
  }

  function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs.sendForm('service_7aytbqg', 'template_abp2pqp', e.target, '4RFGq8Wz5ym0or0CV')
      .then((result) => {
          // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
      setEmailSent(true)
  }

  const inputClassName = 'p-1 w-full m-1 bg-black/40 border border-gray-700 rounded-xl border-2 text-white'

  return (
    <>
    {emailSent ? 
    <div className='flex h-screen justify-center'>
        <p className='text-white'>Your message was sent, we'll be in touch shortly!</p>
    </div>
    :
    <div className='flex justify-center font-sans'>

    <div className='w-2/3'>
    <form className="w-full" onSubmit={sendEmail}>
      {/* <input type="hidden" name="contact_number" /> */}
      <input type="text" name="from_name" placeholder='Name' className={inputClassName} required/>
      <input type="email" name="from_email" placeholder='Email' className={inputClassName} required/>
      <input type="phone" name="from_phone" placeholder='Phone Number' className={inputClassName} required/>
      <div className='flex items-center'>
        {!dateNotSet ? 
<div className='pr-4'>
      <DatePicker name="event_date" dateFormat='Pp' placeholder='Event start date/time' locale="enGB" className={inputClassName} showTimeSelect selected={startDate} onChange={(date) => setStartDate(date)} />
  </div>
    : null }
      <div className='flex justify-start w-1/2 items-center px-2 py-4'>
      <input checked={dateNotSet} onClick={handleClick} type="checkbox" value={dateNotSet} name='datenotset' /> <p className='pl-2'>Date not set yet</p>
        </div>
      </div>
      <input type="text" name="eventAddress" placeholder='Event address' className={inputClassName}/>
      <input type="text" name="eventType" placeholder='Event type' className={inputClassName}/>
      <input type="text" name="eventBudget" placeholder='Budget' className={inputClassName}/>

      {/* <div className='flex flex-col pb-8'>
        <p className='text-center py-8'>Please ONLY pick the option(s) you would like a quote for:</p>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Balloon Wall" name="Balloon Wall" /> <p className='pl-2'>Balloon Wall</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Moongate" name="Moongate" /> <p className='pl-2'>Moongate (Hoop)</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Organic Balloon Garland" name="Organic Balloon Garland" /> <p className='pl-2'>Organic Balloon Garland</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Copper Frame" name="Copper Frame" /> <p className='pl-2'>Copper Frame with Acrylic Sign</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Gold Rectangle Frame" name="Gold Rectangle Frame" /> <p className='pl-2'>Gold Rectangle Frame with Acrylic Sign</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Neon Signs" name="Neon Signs" /> <p className='pl-2'>Neon Signs</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Number/Character Balloon" name="Number/Character Balloon" /> <p className='pl-2'>Number/Character Balloon Display/Columns</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Hole in Head" name="Hole in Head" /> <p className='pl-2'>Hole in Head Carnival Theme Stand</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Artifical flowers" name="Artifical flowers" /> <p className='pl-2'>Artifical flowers in arrangement</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Selfie Swing" name="Selfie Swing" /> <p className='pl-2'>Vintage Floral Selfie Swing</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Pipe and Drape" name="Pipe and Drape" /> <p className='pl-2'>8ft by 8ft Pipe and Drape Stand</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Copper frame with Macrame" name="Copper frame with Macrame" /> <p className='pl-2'>Copper frame with Macrame</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Boho Parasol" name="Boho Parasol" /> <p className='pl-2'>Boho Parasol</p>
        </div>
        <div className='flex justify-start tablet:justify-center border-b border-b-gray-500'>
      <input type="checkbox" value="Picnic Set ups" name="Picnic Set ups" /> <p className='pl-2'>Picnic Set ups</p>
        </div>
      </div> */}

      <textarea name="html_message" placeholder='Anything that may be useful. Colours, themes, Number of guests. Tell us anything relevant here.' className={`${inputClassName} h-32`}/>
      <input type="submit" value="SEND" className='border border-gray-700 border-2 rounded-xl text-white w-full m-1 p-1 bg-gray-700 hover:bg-black hover:text-gray-700'/>
    </form>
    </div>
    </div>
    }
    </>
  );
}