import React from 'react'
import Hero from '../Components/Hero'
import useWindowDimensions from '../Components/Tools/WindowDimensions'
import shop from '../Assets/Images/shop.png'
import {motion} from 'framer-motion'

function Home() {
    const {width} = useWindowDimensions()
  return (
      <>
      <motion.div 
    initial={{translateY:'100%'}}
    animate={{translateY:'0%', transition: {duration: 2}}}
    exit={{translateY:'-100%', transition: {duration: 0.2}}}
    className='text-gray-700 pt-4 bg-bellablue'>


      
          <h2 className='text-center p-8'>Welcome to Bella Blue</h2>


<div className='flex flex-row justify-center p-4'>
    <img src={shop} className='w-2/3 rounded-xl shadow-2xl'/>
</div>

      <div className='bg-white rounded-md p-4 mx-16 text-center shadow-2xl'>
    <p className='text-bellablue p-1'>
    Bella Blue Events is a Luxury Balloon Artist that specialises in event decor for all occassions! 
    </p>
    <p className='text-bellablue p-1'>Our signature looks are designed with our clients in mind using custom colours and the most trusted branded products. We have a variety of props and stands to really give your event that Wow Factor that we are all after!</p>
<p className='text-bellablue p-1'>
We take pride in offering you an individual design to suit your budget and offering you that show stopping look to make your event truly unforgettable! 
</p>
<p className='text-bellablue p-1'>
This truly is our passion- we absolutely love what we do and this shows in our work! 
    </p>
      </div>

      <div className='h-8'>
        
      </div>

      </motion.div>
      </>
  )
}

export default Home