import React from 'react'

function AdminLogin() {
    return (
        <div className='bg-bellablue h-screen p-8'>


<div className='flex justify-center'>
        <div className='bg-white rounded-xl flex flex-col w-1/2 p-8'>
        <h2 className='text-center text-black'>Admin login</h2>

        <input className='border rounded-xl p-2 m-2' placeholder='Email'/>

        <input className='border rounded-xl p-2 m-2' placeholder='Password' type='password'/>

        <button className='hover:text-white hover:bg-bellablue border border-bellablue rounded-xl p-2 m-2 px-16'>Log in</button>
        </div>
</div>

        </div>

      )
}

export default AdminLogin