import React from 'react'
import { motion } from 'framer-motion'
import logo from '../Assets/Images/logo.png'

//Make it do-able to accept individual ts and cs from admin backend?

function Terms() {
  return (
    <motion.div
      initial={{ translateY: '100%' }}
      animate={{ translateY: '0%', transition: { duration: 2 } }}
      exit={{ translateY: '-100%', transition: { duration: 0.2 } }}
      className='text-gray-700 pt-4 bg-bellablue'>
      <div className='bg-bellablue p-8 text-center text-white mx-16'>
        <div className='flex justify-center'>
          <img src={logo} className='w-64 h-64' />
        </div>

        <h2>Terms and Conditions</h2>
        <p>
          In order for your date to be secured, you must pay the non-refundable booking fee. This is
          50% of your overall invoice. By paying this you are agreeing to our Terms and Conditions
          as stated below. Although the booking fee is non-refundable, we do understand that
          sometimes dates need to be unavoidably changed. In this instance, we will transfer your
          deposit to another date subject to availability. Once a booking has been made and deposit
          paid, items may be added, subject to availability. Items can also be swapped to the same
          or more value. Amounts cannot be reduced.
        </p>

        <h2>Balloons</h2>
        <p>
          Once the balloons are in your possession, they are your responsibility. We ensure that
          there are no faults before these are delivered and are not liable for any damage once
          handed over. We ask that children are not left unattended with the balloons as these can
          be a choking hazard. Change of temperature can affect your balloons- If it gets too hot,
          they will expand and are at risk of bursting, and if they get cold, they can shrink.
          Please keep away from direct sunlight/radiators etc and do not leave balloons in your car.
          Balloons are very delicate so please keep away from sharp objects. Balloons that are kept
          inside can last up to 4 weeks. Balloons that are kept outside will only last between 1-2
          days. The majority of our balloons are latex so please let us know in advance if anyone
          has a latex allergy. Although all of the balloons we provide are biodegradable, we do not
          offer helium balloons for releasing.
        </p>

        <h2>Prop hire</h2>
        <p>
          We fault check all of our props before delivery. You will be liable for the full amount to
          replace if these are damaged in your possession. The same applies if any of our stock is
          missing i.e. Flowers/foliage. In this instance, you will be invoiced in full to replace.
          Our props are HIRE ONLY. We will arrange a time with you to collect after your event. If
          you are having an outdoor installation, we ask that you take the props indoors in the
          event of rain to protect them from the elements. Please note that you should NOT remove
          anything from the displays. This includes- florals, neon’s, and acrylic personalised
          signs. If an item that you have hired as become damaged/lost before your booking then we
          will make every effort to replace in time for your event. If this is not possible then you
          will be entitled to a full refund of this prop only. If you have chosen to collect props,
          then you are agreeing to ensure that you have sufficient space available for transit and
          be able to secure accordingly to avoid any damage. Any damage that does occur will be your
          responsibility and charged accordingly to rectify. None of our props should be left
          unattended or in unsecure locations. If you have chosen to drop back our props yourself,
          they must only be delivered to Unit 2.23 St Nicholas Centre, Sutton and handed over to a
          member of staff and not left unattended. For indoor installations, we may use command
          hooks to attach. Although it is rare, these can cause minor damage to paint work. We will
          take precautions to lower the risk as much as possible, but please let us know if you are
          not happy with these being used. If full payment has not been made by the date specified
          on the invoice then we hold no responsibility to provide the hire and hold no liability in
          this instance. If you choose to re schedule your event once deposit has been made, a later
          date must be confirmed within 4 weeks (unless otherwise agreed by us at our discretion)
          otherwise the deposit will be void. By paying your 50% deposit, you are agreeing to these
          terms.
        </p>
      </div>
    </motion.div>
  )
}

export default Terms
