import React from 'react'
import './App.css'
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import Home from './Screens/Home';
import HeaderBar from './Components/HeaderBar';
import SideNav from './Components/SideNav';
import useWindowDimensions from './Components/Tools/WindowDimensions';
import About from './Screens/About';
import Portfolio from './Screens/Portfolio';
import Enquiry from './Screens/Enquiry';
import Charity from './Screens/Charity';
import FAQs from './Screens/FAQs';
import SocialIcons from './Components/SocialIcons';
import {AnimatePresence} from 'framer-motion'
import ScrollToTop from './Components/Tools/ScrollToTop';
import Footer from './Components/Footer';
import AdminLogin from './Components/Admin/AdminLogin';
import Terms from './Components/Terms';

function App() {
  const {width, height} = useWindowDimensions()

  
  return (
    <>

      <BrowserRouter>

      {width < 700 || height < 700 ? 
      <HeaderBar />
      :
      <SideNav />
    }

    <div className={width < 700 || height < 700 ? 'bg-bellablue' : 'w-3/4 bg-bellablue'}>

    <AnimatePresence>
      <ScrollToTop>

      <Routes >
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/charity" element={<Charity />} />
        <Route path="/FAQs" element={<FAQs />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/terms" element={<Terms />} />

    </Routes>
      </ScrollToTop>
    </AnimatePresence>
    </div>
    <Footer />
  </BrowserRouter>





 
    </>
  )
}

export default App