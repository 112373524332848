import React from 'react'
import { motion } from 'framer-motion'


function FAQs() {
  return (
    <motion.div
      initial={{ translateY: '100%' }}
      animate={{ translateY: '0%', transition: { duration: 2 } }}
      exit={{ translateY: '-100%', transition: { duration: 0.2 } }}
      className='text-gray-700 pt-4 bg-bellablue h-screen'>
      <div className='bg-bellablue h-screen p-8'>
        <h2 className='text-center p-8'>FAQs</h2>

        <div className='text-center'>
          <h3 className='subHeading text-2xl font-bold'>What services do you offer?</h3>
          <p>
            We offer luxury balloon styling and prop hire for any occasion! We have lots of props to
            choose from and we also custom make props to suit your theme so nothing is out of reach.
            We also do picnic set ups and table scaping!
          </p>

          <h3 className='subHeading text-2xl font-bold pt-8'>What areas do you cover?</h3>

          <p>
            We mainly cover within Surrey but we also undertake work in London. Delivery charges
            will depend on post code.
          </p>
          <h3 className='subHeading text-2xl font-bold pt-8'>What are your prices?</h3>
          <p>
            There are many factors that we look at before pricing any job. Each one is bespoke so it
            all depends on the size of the display, the colour scheme and address of set up. Our
            installations start from £140 but we also do collections to suit a smaller budget. Get
            in touch with any ideas you have and we can give you a personalised quote!
          </p>

          <h3 className='subHeading text-2xl font-bold pt-8'>What is your refund and cancellation policy?</h3>
          <p>
            As stated in our Terms and Conditions, once your deposit is paid, this is non
            refundable. If you need to reschedule your event, we will transfer the deposit to a new
            date subject to availability. A new date must be confirmed within 4 weeks unless
            otherwise agreed, otherwise your deposit will be lost. Once a booking has been made,
            items can be swapped or changed- subject to availability- to the same value or more.
          </p>
          <h3 className='subHeading text-2xl font-bold pt-8'>How long do set ups take?</h3>
          <p>
            Again, this is dependant on the size of the set up. Our larger set ups may take over an
            hour, but smaller ones may be done in 20 minutes. We will let you know how long we need
            for set up once we know what sort of design you are looking for.
          </p>
        </div>
      </div>
    </motion.div>
  )
}

export default FAQs
