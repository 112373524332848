import React from 'react'
import { Link } from 'react-router-dom'
import SocialIcons from './SocialIcons'
import useWindowDimensions from './Tools/WindowDimensions'

function Footer() {
    const {height, width} = useWindowDimensions()
  return (
<div className={width > 700 ? 'w-3/4 text-center pb-4 pt-16 bg-belladarker' : 'text-center pb-4 pt-16 bg-belladarker'}>
  <div>
    <SocialIcons />
  </div>
  <p className='text-sm'>© Bella Blue Ltd {new Date().getFullYear()} | <a href='https://www.thegeekfloor.com'>Site by <span className='hover:text-tgf'>TheGeekFloor</span></a></p>
  <Link to='/admin'><p className='text-sm pt-4 hover:text-black'>Admin login</p></Link>
</div>
  )
}

export default Footer